<script setup>

</script>

<template>
  <div class="section-wrp-podd" id="support">
    <div class="wrp-all">
      <div class="section-podder">
        <div class="left-podder">
          <h2>поддержка сайтов</h2>
          <ul class="podder">
            <li>Обеспечение<br> работоспособности сайта</li>
            <li>Работы по<br> программированию</li>
            <li>Верстка<br>макетов</li>
          </ul>
          <p>Услуги по графической, контентной поддержке. Оперативные изменения на Вашем сайте.</p>
        </div>
        <div class="right-podder">
          <span>Доработки функционала, замены CMS сайта с учетом SEO-продвижения.</span>
          <ul class="podder">
            <li>Разработка<br>баннеров и других графических материалов</li>
            <li>Выгрузка прайс-листов, синхронизация с 1С</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
