<script setup>

</script>

<template>
  <footer class="main-footer" id="contact">
    <div class="foot-top">
      <div class="wrp-all">
        <div class="row">
          <div class="">
            <b>АДРЕС: <br>
              142730, Москва , поселение Сосенское, Ж/к Дубровка</b>
          </div>
        </div>
      </div>
    </div>
    <div class="foot-btm">
      <div class="wrp-all">
        <nav>
          <ul class="mnu-top mnu-bottom">
            <li><a href="#developer">создание сайтов</a></li>
            <li><a href="#support">поддержка</a></li>
            <li><a href="#advert">реклама</a></li>
            <li><a href="#portfolio">портфолио</a></li>
          </ul>
        </nav>
      </div>
    </div>
  </footer>
</template>

<style scoped lang="scss">

</style>
