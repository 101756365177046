<script setup>

</script>

<template>
  <div class="section-site" id="developer">
    <div class="wrp-all">
      <div class="site-main">

        <h2>Создание сайтов</h2>

        <div class="site-main-wrp-block">
          <div class="site-main-block">
            <img src="/img/razrabotka-ico.jpg" alt="ico">
            <p>разработка<br> концепции</p>
          </div>
          <div class="site-main-block">
            <img src="/img/ustanovka-ico.jpg" alt="ico">

            <p>Установка системы<br> управления (CMS)</p>
          </div>
          <div class="site-main-block">
            <img src="/img/napolnenie-ico.jpg" alt="ico">

            <p>Наполнение сайта</p>
          </div>
          <div class="site-main-block">
            <img src="/img/proekt-ico.jpg" alt="ico">

            <p>Проектирование<br> и дизайн интерфейса</p>
          </div>
          <div class="site-main-block">
            <img src="/img/functiont-ico.jpg" alt="ico">

            <p>программирование<br> функционала
            </p>
          </div>
        </div>
        <div class="site-main-block-card">
          <div class="site-block-card">
            <img src="/img/logotype-ico.jpg" alt="logotype">
            <p>разработка логотипов<br> и фирменного стиля</p>
          </div>
          <div class="site-block-card">
            <img src="/img/brandbook-ico.jpg" alt="brandbook">
            <p>создание<br> бренд-бука</p>
          </div>
          <div class="site-block-card">
            <img src="/img/poligraf-ico.jpg" alt="poligraf">
            <p>полиграфическая<br> продукция</p>
          </div>
          <div class="site-block-card-img">
            <ul>
              <li><a href="/img/site-block-card-img.png" title="Работа 10" class="popup2">
                <img src="/img/site-block-card-img.png" alt="alt"></a>
              </li>
              <li><a href="/img/site-block-card-img.png" title="Работа 11" class="popup2">
                <img src="/img/site-block-card-img.png" alt="alt"></a>
              </li>
              <li><a href="/img/site-block-card-img.png" title="Работа 12" class="popup2">
                <img src="/img/site-block-card-img.png" alt="alt"></a>
              </li>
              <li><a href="/img/site-block-card-img.png" title="Работа 13" class="popup2">
                <img src="/img/site-block-card-img.png" alt="alt"></a>
              </li>
              <li><a href="/img/site-block-card-img.png" title="Работа 14" class="popup2">
                <img src="/img/site-block-card-img.png" alt="alt"></a>
              </li>
            </ul>
          </div>
          <a class="back2">назад</a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
