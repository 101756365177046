<script setup>

const contentOrder = {
  title: '',
  body: '',
  content: ''
}
console.dir(contentOrder)
</script>

<template>
  <div class="section-wrp-internet-market">
    <div class="wrp-all">
      <div class="section-internet-market" id="advert">
        <div class="left-market">
          <h2>интернет маркетинг</h2>
          <ul>
            <li><span>Продвижение в<br> поисковых системах</span>
              <small>Seo-продвижение в поисковых системах. Увеличим посещяемость сайта, поможем увеличить конверсию на
                сайте.
              </small>
            </li>
            <li><span>Контекстная реклама</span>
              <small>Контекстная и медийная реклама в Яндексе и Гугле. Добъемся максимального эффекта с вашим бюджетом.
              </small>
            </li>
          </ul>
        </div>
        <div class="right-market">
          <p>Эффективная реклама и анализ для Вашего бизнеса. </p>
          <ul>
            <li><span>Продвижение в соц.<br> сетях (SMM/SMO)</span>
              <small>Ведение групп в социальных сетях, таргетированная реклама.
              </small>
            </li>
            <li><span>SEO аудит сайта</span>
              <small>Аудит вашей рекламы: seo-продвижения, контекстной рекламы. Устраним неточности, мешающие
                продвижению. Снизим цену обращения покупателя.
              </small>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
