<script setup>
import { ref, computed, defineProps } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, minLength, helpers, email } from '@vuelidate/validators'
import orderService from "@/services/order.service"
import { IMaskDirective } from 'vue-imask'
import { createToaster } from "@meforma/vue-toaster"

const props = defineProps({
  content: Object
})

const visibleForm = ref(false)
const toaster = createToaster({ /* options */})
// eslint-disable-next-line no-unused-vars
const imask = IMaskDirective

const maskPhone = {
  mask: '+{7}(000) 000-0000',
  lazy: true
}

const order = ref({
  phone: '',
  name: '',
  email: '',
  message: ''
})

const validations = computed(() => {
  return {
    name: {
      required: helpers.withMessage('Обязательно к заполнению', required),
      minLength: helpers.withMessage('Очень короткое имя', minLength(3))
    },
    phone: {
      required: helpers.withMessage('Обязательно к заполнению', required),
      minLength: helpers.withMessage('10 цифр в номере', minLength(16))
    },
    email: {
      required: helpers.withMessage('Обязательно к заполнению', required),
      email: helpers.withMessage('Неправильный формат почты', email)
    },
    message: {
      required: helpers.withMessage('Обязательно к заполнению', required)
    }
  }
})

const v$ = useVuelidate(validations, order)

function toggleState () {
  visibleForm.value = !visibleForm.value
}

async function someAction () {
  const isFormCorrect = await v$.value.$validate()

  if (!isFormCorrect) return
  const result = await orderService.send(order.value)
  if (result) {
    toaster.success('Ваша заявка принята, ожидайте ответа')
  } else {
    toaster.error('!!!')
  }
}

</script>

<template>
  <div class="wrp-head-btm">
    <div class="wrp-all">
      <div class="head-btm">
        <h3>
          <small>{{ props.content.title }}</small>
          <span>{{ props.content.body }}</span></h3>
        <p>{{ props.content.content }}</p>

        <div class="head-phone">
          <span>{{ phone }}</span>
        </div>
        <div
          class="head-btm"
          v-show="!visibleForm"
        >
          <button
            @click="toggleState"
            class="btn_submit btn_orange"
          >Оставить заявку
          </button>
        </div>
        <form
          @submit.prevent="someAction"
          v-show="visibleForm"
          class="head-form"
          action=""
          id="develop">
          <div class="form-control">
            <input
              type="tel"
              v-model="order.phone"
              v-imask="maskPhone"
              class="user_phone"
              name="phone"
              placeholder="+7(000) 000-0000">
            <div
              v-if="v$.phone.$dirty && v$.phone.required.$invalid"
              class="input-error"
            >
              {{ v$.phone.required.$message }}
            </div>
            <div
              v-if="v$.phone.$dirty && v$.phone.minLength.$invalid"
              class="input-error"
            >
              {{ v$.phone.minLength.$message }}
            </div>
          </div>
          <div class="from-control">
            <input
              v-model="order.email"
              class="user_phone"
              name="email"
              placeholder="Ваша почта">
            <div
              v-if="v$.email.$dirty && v$.email.required.$invalid"
              class="input-error"
            >
              {{ v$.email.required.$message }}
            </div>
            <div
              v-if="v$.email.$dirty && v$.email.email.$invalid"
              class="input-error"
            >
              {{ v$.email.email.$message }}
            </div>
          </div>
          <div class="from-control">
            <input
              type="text"
              v-model="order.name"
              placeholder="Ваше имя"
              name="name">
            <div
              v-if="v$.name.$dirty && v$.name.required.$invalid"
              class="input-error"
            >
              {{ v$.name.required.$message }}
            </div>
            <div
              v-if="v$.name.$dirty && v$.name.minLength.$invalid"
              class="input-error"
            >
              {{ v$.name.minLength.$message }}
            </div>
          </div>
          <textarea
            v-model="order.message"
            rows="3"
            placeholder="Ваше сообщение"
          />
          <div
            v-if="v$.message.$dirty && v$.message.required.$invalid"
            class="input-error"
          >
            {{ v$.message.required.$message }}
          </div>
          <div class="form-control__btn">
            <input
              type="submit"
              value="Отправить"
              class="btn_submit"
              style="">
          </div>

        </form>
      </div>
    </div>
  </div>

</template>

<style scoped lang="scss">
</style>
