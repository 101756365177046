export default {
  data () {
    return {
      title: 'Интернет-агентство Каркадос',
      phone: '+7(905)726-5510',
      formatPhone: '+79057265510',
      phoneWhatsapp: '79057108035',
      email: 'alex@carcados.ru'
    }
  }
}
