<script setup>
import Header from '../layouts/Header'
import Footer from '../layouts/Footer'
import SectionSite from '../components/SectionSite'
import SectionSupport from '../components/SectionSupport'
import SectionMarket from '../components/SectionMarket'
import SectionPortfolio from '../components/SectionPortfolio'
import SectionText from '../components/SectionText'
import OrderSite from '../components/OrderSite'

const contentSupport = {
  title: 'вам нужна поддержка сайта?',
  body: 'автоматизация работы или синхронизация со складской программой?',
  content: ''
}

const contentAll = {
  title: '',
  body: 'Поможем решить любые Ваши проблемы',
  content: ''
}

</script>

<template>
  <Header/>
  <SectionSite/>
  <SectionSupport/>
 <OrderSite :content="contentSupport"/>
  <SectionMarket/>
  <SectionPortfolio/>
  <SectionText/>
  <OrderSite :content="contentAll"/>
  <Footer/>
  <div class="hidden"/>
</template>
