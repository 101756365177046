<script setup>
import { onMounted, ref } from "vue"
import { gsap } from "gsap"
import OrderSite from "../components/OrderSite"

onMounted(() => {
  gsap.from(".logo", {
    duration: 7,
    delay: 0.3,
    x: -650,
    opacity: 0,
    ease: "elastic",
    repeat: 0
  })
})

// eslint-disable-next-line max-len
const title = ref("Разработка и поддержка сайтов, планирование и проведения рекламных кампаний, парсинг, автоматизация процессов")

const menu = [
  {
    id: 1,
    name: 'tg',
    link: 'https://t.me/kaiseri',
    icon: 'tg-small.webp'
  },
  {
    id: 1,
    name: 'wp',
    link: 'https://wa.me/79057265510',
    icon: 'wp-small.webp'
  }
]

const orderContent = {
  title: 'вам нужен сайт?',
  body: 'или решение ваших задач?',
  content: 'Получить качественное и продающее представительство в интернете гораздо проще, чем Вы думаете. Достаточно\n' +
    '          позвонить нам или оставить заявку.'
}

function getImgUrl (pic) {
  return require('../assets/img/' + pic)
}

</script>

<template>
  <header class="main-head">
    <div class="top-line">
      <div class="wrp-header">
        <nav>
          <ul class="mnu-top">
            <li
              v-for="link in menu"
              :key="link.id">
              <a v-bind:href="link.link">
                <img
                  :src="getImgUrl(link.icon)"
                  width="30">
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="wrp-all">
      <div class="head-top">
       <img
          class="logo"
          src="/img/logo-head.png"
          alt="logo">

        <h1>{{ title }}</h1>
      </div>
    </div>
    <OrderSite :content="orderContent"/>
  </header>
</template>

<style scoped lang="scss">
.top-line {
  display: flex;
}
</style>
