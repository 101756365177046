<script setup>
import {Swiper, SwiperSlide} from 'swiper/swiper-vue.mjs'
import {Pagination, EffectCoverflow} from 'swiper/modules'
import 'swiper/swiper.css'
import 'swiper/modules/pagination.css'

const onSwiper = (swiper) => console.log('1')
const onSwiperSlide = () => console.log('2')
const modules = [Pagination, EffectCoverflow]
const clients = [
  {
    key: 1,
    name: 'Телевизоры в зеркале',
    href: 'https://tv-zerkalo.ru',
    img: 'tvzerkalo_small.png'
  },
  {
    key: 2,
    name: 'Интернет магазин часов Timeplus',
    href: 'https://timepluss.ru',
    img: 'timeplus_small.png'
  },
  {
    key: 3,
    name: 'Ресторан Таверна Барракуда',
    href: null,
    img: 'taverna_small.png'
  },
  {
    key: 3,
    name: 'Монтаж отопления ЛФОРТ',
    href: null,
    img: 'lfort_small.png'
  },
  {
    key: 3,
    name: 'Дизайн студия Дует',
    href: null,
    img: 'duet_small.png'
  },
  {
    key: 3,
    name: 'Авиационные двигатели Амнтксоюз',
    href: 'https://amntksoyouz.ru',
    img: 'amntk_small.png'
  },
  {
    key: 3,
    name: 'Авточехлы Вега',
    href: null,
    img: 'auto_small.png'
  }// Самрты, Ремида, Строители
]
</script>

<template>
  <div class="section-wrp-our-clients" id="portfolio">
    <div class="wrp-all">
      <div id="our-clients-main">
        <h2>наши клиенты</h2>
        <div class="items-block-logo">
          <swiper
            @swiper="onSwiper"
            @swiperSlide="onSwiperSlide"
            :modules="modules"
            :slidesPerView="3"
            :breakpoints="{
              '320':{
                slidesPerView: 1,
                spaceBetween: 10,
              },
              '490':{
                slidesPerView: 2,
                spaceBetween: 30,
              },
              '910':{
                slidesPerView: 3,
                spaceBetween: 50,
              }
            }"
            :pagination="true"
            :effect="'coverflow'"
            :coverflowEffect="{
                rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }"
          >
            <swiper-slide v-for="client in clients" :key="client.key">
              <div class="item_slide">
              <a :href="client.href" class="link_slide" v-if="client.href !== null">
                <img :src="'/images/portfolio/'+ client.img ">
              </a>
              <img :src="'/images/portfolio/'+ client.img" v-else>
              <span class="text_slide">{{ client.name }}</span>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
