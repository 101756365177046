<script setup>

</script>

<template>
  <div class="section-wrp-main-text">
    <div class="wrp-head-btm">
      <div class="wrp-all">
        <div class="section-main-text">
          <img src="/img/logo-content.png" alt="logo">

          <div class="main-text" id="agent">
            <p>Интернет агентство «Каркадос» более 10 лет оказывает услуги по разработке, поддержке сайтов , продвижению сайтов и ведение контекстной и таргетированной рекламы. Уникальные технологии собственной разработки позволяют высокоэффективно вести разработку проектов и снижать стоимость оформления заказа при ведении рекламы. <br> Качество оказываемых услуг подтверждает наше портфолио и многочисленные сертификаты от Яндекс, Google.</p>

            <p>Мы готовы решить любые Ваши задачи в интернет пространстве. Мы не будем предлагать Вам сложное и дорогостоящее решение, если есть простое. Эффективно проведем Вашу рекламную кампанию или оптимизируем существующую. </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
