import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import config from "./layouts/global/config"
import router from './router'
import store from './store'
import { IMaskDirective } from 'vue-imask'
import "@/styles/style.scss"
import Toaster from "@meforma/vue-toaster"
import VueYandexMetrika from 'vue3-yandex-metrika'

createApp(App)
  .directive('imask', IMaskDirective)
  .mixin(config)
  .use(store)
  .use(Toaster)
  .use(router)
  .use(VueYandexMetrika, {
    id: 95645381,
    router: router,
    env: process.env.NODE_ENV,
    options: { clickmap: true, trackLinks: true, accurateTrackBounce: true, webvisor: true }
  })
  .mount('#app')
