import axios from 'axios'

const orderService = {
  async send (order) {
    // eslint-disable-next-line no-undef
    const result = await axios('https://report.carcados.ru/api', {
      method: 'POST',
      headers: {
        Origin: 'https://carcados.ru',
        'User-Agent': ' curl/7.51.0',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PATCH,OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Accept, Content-Type, Access-Control-Allow-Origin',
        'Content-Type': 'application/json; charset=UTF-8'
      },
      data: order
    })
    if (result.status === 200) {
      return true
    }
    return false
  }
}

export default orderService
